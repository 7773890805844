import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import '@intuitivo/outline/dist/index.css';
import * as Sentry from '@sentry/react';
import 'moment/locale/pt';
import { createRoot } from 'react-dom/client';
import ReactGA from 'react-ga';
import { Provider } from 'react-redux';

import App from 'App';
import 'assets/css/fonts.css';
import 'assets/css/react-datetime.css';
import { msalConfig } from 'authConfig';
import 'index.css';
import 'polyfills';
import store from 'store';

import * as serviceWorker from './serviceWorker';

/* Sentry */
Sentry.init({
  environment: process.env.REACT_APP_NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: process.env.REACT_APP_TRACES_SAMPLE_RATE,
  tracePropagationTargets: new RegExp(process.env.REACT_APP_PROPAGATION_TARGETS),
  replaysSessionSampleRate: process.env.REACT_APP_REPLAY_SAMPLE_RATE,
  replaysOnErrorSampleRate: process.env.REACT_APP_REPLAY_ON_ERROR_SAMPLE_RATE,
});

/* Google Analytics */
ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);

/* Microsoft Authentication Libraries */
const publicClientApplication = new PublicClientApplication(msalConfig);

createRoot(document.getElementById('root'))
  .render(
    <React.StrictMode>
      <MsalProvider instance={publicClientApplication}>
        <Provider store={store}>
          <App />
        </Provider>
      </MsalProvider>
    </React.StrictMode>,
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
